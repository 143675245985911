import * as React from 'react'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import * as theme from '../../../theme'

const CustomizeButton = styled(Button)({
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  borderRadius:10,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: theme.SUBSCRIBE_BUTTON,
    borderColor: theme.SUBSCRIBE_BUTTON,
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.SUBSCRIBE_BUTTON,
    borderColor: theme.SUBSCRIBE_BUTTON,
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: theme.SUBSCRIBE_BUTTON,
    borderColor: theme.SUBSCRIBE_BUTTON,
  }
})



export default function ActionButton(props) {
  const {buttonText, upgrade, ...rest }  = props
  return (
      <CustomizeButton sx={{ color: upgrade?theme.SUBSCRIBE_BUTTON_FONT:theme.SUBSCRIBED_BUTTON_FONT, backgroundColor: upgrade?theme.SUBSCRIBE_BUTTON:theme.SUBSCRIBED_BUTTON, borderColor: upgrade?theme.SUBSCRIBE_BUTTON:theme.SUBSCRIBED_BUTTON,}} variant="contained" {...rest}>
        {buttonText}
      </CustomizeButton>
  )
}