import React from 'react';

import { Box } from '@mui/material';

import Header from '../header/Header';
import HomeBody from './HomeBody';
import Footer from '../footer/Footer';

import * as theme from '../../theme'

export default function Home() {

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Box >
        <Header type='home' />
      </Box>
      <Box sx={{backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, padding: 4, minHeight: '80vh' }}>
        <HomeBody />
      </Box>
      <Box>
        <Footer />
      </Box>
    </div>
  );
}
