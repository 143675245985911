// Popup.js
import React, { useState } from 'react';
import { Modal, Box, IconButton, Button, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { styled } from '@mui/system';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '16px', // Rounded corners
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Elevated shadow
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const PulsingMicIcon = styled(MicIcon)`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: pulse 1.5s infinite;
`;

const Popup = ({ open, handleOpen, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="popup-title"
        aria-describedby="popup-description"
        BackdropProps={{ onClick: (event) => event.stopPropagation() }}
      >
        <Box sx={style}>
          <Typography id="popup-title" variant="h6" component="h2">
            Listening...
          </Typography>
          <IconButton color="primary" aria-label="mic icon">
            <PulsingMicIcon fontSize="large" sx={{ color: '#002a3a'}} />
          </IconButton>
          <Button variant="contained" onClick={handleClose} sx={{ backgroundColor: '#002a3a', color: 'white', '&:hover': { backgroundColor: '#001f2a' } }}>
          <RecordVoiceOverIcon />  Answer
          </Button>
          <p>
            When you are done asking the question Click on the 'Answer' button to get a response
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
