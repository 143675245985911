import * as React from 'react'

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { TextField, Tooltip } from '@mui/material'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import * as theme from '../../../theme'



const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': theme.TEXT_FIELD_BORDER,
            '--TextField-brandBorderHoverColor': theme.TEXT_FIELD_HOVER,
            '--TextField-brandBorderFocusedColor': theme.TEXT_FIELD_FOCUS,
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '&.Mui-disabled': {
              color: theme.BODY_FONT,
              
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',   
            },
            
          },
        },
      },
      
     
    },
  });

export default function InputField(props) {
    const { tooltipTitle = '', multiple=false, label, accept, name, ...rest } = props;
    const outerTheme = useTheme();
  
    return (
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Tooltip title={tooltipTitle} style={{ margin: 0, padding: 0, fontSize: '20px', display: 'flex', alignItems: 'center', }}>
          {
            label &&
          <span style={{minWidth: 100, textAlign: 'start'}}>
            {label} : 
          </span>
          }
          <TextField
            name={name}
            type="file"
            inputProps={{ multiple: multiple, accept: accept? accept: '*/*' }} 
            {...rest}
            InputLabelProps={{
              sx: { color: theme.TEXT_FIELD_LABEL },
            }}
            InputProps={{
              sx: {
                color: theme.BODY_FONT,
                backgroundColor: theme.BODY_BG,
              },
            }}
          />
        </Tooltip>
      </ThemeProvider>
    );
  }