import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle';

import logo from '../../aiagents.png';
import * as theme from '../../theme'
import { useMediaQuery } from '@mui/material';


export default function Header({type = "home", user, removeToken, removeUser, setLoading}) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const navigate = useNavigate()
  const [accountMenu, setAccountMenu] = useState(null);
  const [navMenu, setNavMenu] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isSupported, setIsSupported] = useState(false);
  const [isInstalled, setisInstalled] = useState(false);
  const MOBILE_BUILD_ONLY = (process.env.REACT_APP_MOBILE_BUILD === "true") || false
  
  const handleAccountMenu = (event) => {
    setAccountMenu(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountMenu(null);
  };


  const handleNavMenu = (event) => {
    setNavMenu(event.currentTarget);
  };

  const handleNavClose = () => {
    setNavMenu(null);
  };

  const handleLogout = () => {
    handleAccountClose()
    removeToken()
    removeUser()
    navigate("/auth")
    setLoading({
      state: true,
      headline: "Logging Out",
      paragraph: "We hope to see you soon"
    }
    )
    setTimeout(() =>{
      setLoading({state:false, headline: "", paragraph: ""})
    }, 1500)
  }
  
  const handleAccount = () => {
    handleAccountClose()
    navigate("/account")
    
  }
  const handleHistory = () => {
    handleAccountClose()
    navigate("/history")
    
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('PWA installed');
      } else {
        console.log('PWA installation dismissed');
      }
      setDeferredPrompt(null);
    }
  };

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setIsSupported(true)
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);
  

  return (
    <Box
  
    >

      {
        isMobile?
        <Box sx={{ flexGrow: 1,  }} >
          <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT,}}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button edge="start" color="inherit" style={{fontSize: 20}} sx={{ mr: 2 }}>
             
           <img src={logo} alt="Logo" width={40} style={{ marginRight: 5 }} />
            {/* AI Agents<span style={{fontSize: "10px"}}>&trade;</span> */}
            </Button>
            <div className="logo-container" style={{textAlign: 'center'}}>
              <div>AI Agents<span style={{fontSize: "10px"}}>&trade;</span></div>
              <div className="tagline" style={{fontFamily: 'Courier New, monospace'}}>Talk, don’t type</div>
            </div>
            
              {
                type === "auth"?
                <Button onClick={() => navigate("/")} color="inherit">
                  <ArrowBackIcon sx={{mr: 1}} /> Home Page
                </Button>:
                type === "home"?
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <MenuIcon sx={{ mr: 1, color: 'black' }} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => navigate("/auth")}>
                      <PersonIcon sx={{ mr: 1 }} /> Signup / Login
                    </MenuItem>
                    <MenuItem onClick={() => window.open("https://aiassistantexpert.com/contact/", '_blank') }>
                        <ContactSupportIcon sx={{ mr: 1 }} /> Support
                    </MenuItem>
                    <MenuItem onClick={() => window.open("https://aiassistantexpert.com", '_blank')}>
                        <WebAssetIcon sx={{ mr: 1 }} /> Website
                    </MenuItem>
                  </Menu>
                </div>:
                type === "assistant"?
                <div>
                  <IconButton
                  onClick={handleAccountMenu}
                  id="auth-menu-button"
                  aria-controls={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  color="inherit"
                >
                 
                    <AccountCircle sx={{ width: 40, height: 40 }} />
           
                </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={accountMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'auth-menu-button',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(accountMenu)}
                    onClose={handleAccountClose}
                  >
                    <MenuItem onClick={handleHistory}>History</MenuItem>
                    <MenuItem sx={{color:
                    "black", fontWeight:
                    "normal"}} onClick={handleAccount}>
                      Account
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>:
                ""
              }
          </Toolbar>
        </AppBar>
        </Box>:
      <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT, py: 1 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button edge="start" color="inherit" style={{fontSize: 15}} sx={{ mr: 2 }}>
           
         <img src={logo} alt="Logo" width={60} style={{ marginRight: 5 }} />
         {/* AI Agents<span style={{fontSize: "10px"}}>&trade;</span> */}
         <div className="logo-container" style={{textAlign: 'left'}}>
            <div>AI Agents<span style={{fontSize: "10px"}}>&trade;</span></div>
            <div className="tagline" style={{fontFamily: 'Courier New, monospace'}}>Talk, don’t type</div>
          </div>
          </Button>

          
          
          
            {
              type === "auth"?
              <Button onClick={() => navigate("/")} color="inherit">
                <ArrowBackIcon sx={{mr: 1}} /> Back To Home Page
              </Button>:
              type === "home"?
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={() => navigate("/auth")} color="inherit">
                  <PersonIcon sx={{mr: 1}} /> Signup / Login
                </Button>
                <Button onClick={() => window.open("https://aiassistantexpert.com/contact/", '_blank')} color="inherit">
                  <ContactSupportIcon sx={{mr: 1}} /> Support
                </Button>
                <Button onClick={() => window.open("https://aiassistantexpert.com", '_blank')} color="inherit">
                  <WebAssetIcon sx={{mr: 1}} /> Website
                </Button>
              </Box>:
              type === "assistant"?
              <div>
                <IconButton
                onClick={handleAccountMenu}
                color="inherit"
              >
               
                
                  <AccountCircle sx={{ width: 50, height: 50 }} />
    
              </IconButton>
                <Menu
                sx={{ display: { xs: 'none', md: 'block' } }}
                  id="menu-appbar"
                  anchorEl={accountMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(accountMenu)}
                  onClose={handleAccountClose}
                >
                  <MenuItem onClick={handleHistory}>History</MenuItem>
                  <MenuItem sx={{color:
                  "black", fontWeight: 
                  "normal"}} onClick={handleAccount}>
                    Account
                  </MenuItem>                  
                  {isSupported && !isInstalled && (
                    <MenuItem onClick={handleInstall}>Install App</MenuItem>
                  )}

                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>:
              ""
            }
        </Toolbar>
      </AppBar>
      }

    </Box>
  );
}
