class AudioBuffer {
    constructor(audio) {
        this.textBuffer = [];
        this.audio = audio
        this.isPlaying = false
        this.voiceId = null
        this.audioContext = null;
        this.audioQueue = [];
        this.socket = null
        this.nextPlayTime = 0;
    }

    destroy() {
        this.textBuffer = []
        this.isPlaying = false
        this.voiceId = null
    }

    asyncTimeout(duration) {
        return new Promise(resolve => setTimeout(() => {
            this.processBuffer()
            resolve();
        }), duration);
    }

    addToBuffer(text) {
        if (!text) return
        this.textBuffer.push(text);
        this.asyncTimeout(200)
    }

    stop() {
        // 4. Send the EOS message with an empty string
        const eosMessage = {
            "text": ""
        };
    
        this.socket?.send(JSON.stringify(eosMessage));
    }


    setup(voiceId) {
        this.voiceId = voiceId
        const model = 'eleven_turbo_v2';
        const wsUrl = `wss://api.elevenlabs.io/v1/text-to-speech/${this.voiceId}/stream-input?model_id=${model}`;
        this.socket = new WebSocket(wsUrl);

        this.socket.onopen = (event) => {
            const bosMessage = {
                "text": " ",
                "voice_settings": {
                    "stability": 0.5,
                    "similarity_boost": 0.8
                },
                "xi_api_key": process.env.REACT_APP_ELAB_API_KEY,
            };
        
            this.socket.send(JSON.stringify(bosMessage));
        
            // 3. Send the input text message ("Hello World")
            // const textMessage = {
            //     "text": text,
            //     "try_trigger_generation": true,
            // };
        
            // this.socket.send(JSON.stringify(textMessage));
        
        };

        this.socket.onmessage = (event) => {
            const response = JSON.parse(event.data);
        
            if (response.audio) {
                // decode and handle the audio data (e.g., play it)
                console.log("Received audio chunk");
                const { arrayBuffer, length } = this.base64ToArrayBuffer(response.audio);
                this.audioQueue.push(arrayBuffer);
                this.playAudioBuffer()
            } else {
                console.log("No audio data in the response");
            }
        
            if (response.isFinal) {
                // the generation is complete
            }
        
            if (response.normalizedAlignment) {
                // use the alignment info if needed
            }
        };

        this.socket.onerror = (error) => {
            console.error(`WebSocket Error: ${error}`);
        };
        
        // Handle socket closing
        this.socket.onclose = (event) => {
            if (event.wasClean) {
                console.info(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                console.warn('Connection died');
            }
        };
    }

    base64ToArrayBuffer(base64) {
        const binaryData = atob(base64);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) uint8Array[i] = binaryData.charCodeAt(i);

        return { arrayBuffer, length: uint8Array.length };
    }

    async playAudioBuffer() {
        console.log(this.audioQueue.length)
        console.log("play audio status", this.isPlaying)
        if (this.audioQueue.length > 0 && !this.isPlaying) {
            const ad = this.audioQueue.shift()
            console.log(ad)
            console.log(this.audioQueue)
            if (!ad) return // skip if queue audio file in rare case

            const audioBlob = new Blob([ad], { type: 'audio/mpeg' });
            // const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            
            console.log(this.audio)
            this.audio.current.src = audioUrl;
            const playPromise = new Promise((resolve) => {
                this.audio.current.onended = resolve;
            });

            this.audio.current.onended = (event) => {
                console.log(
                    "audio speak over",
                );
                this.isPlaying = false
                this.playAudioBuffer()
            };

            this.audio.current.play().catch(error => {
                console.error('Audio playback failed:', error);
            });

            this.isPlaying = true
            
            await playPromise;
            // const audioBuffer = await this.audioContext.decodeAudioData(ad);
            // const data = new DataView(ad);
            // this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      
            // const audioBuffer = this.audioContext.createBuffer(1, length / 2, 44100);
            // const channelData = audioBuffer.getChannelData(0);
        
            // for (let i = 0; i < data.byteLength; i += 2) {
            //     const sample = data.getInt8(i, true);
            //     channelData[i / 2] = sample / 32768;
            // }
            // const source = this.audioContext.createBufferSource();
            // source.buffer = audioBuffer;
            // source.connect(this.audioContext.destination);
            // if (this.nextPlayTime < this.audioContext.currentTime) this.nextPlayTime = this.audioContext.currentTime;
            // source.start(this.nextPlayTime);
            // this.nextPlayTime += ad.duration;
            // this.isPlaying = true
            // source.onended = () => {
            //     console.log("play eneded")
            //     this.isPlaying = false
            //     this.playAudioBuffer()
            // };
        }
    }

    async playAudio(audioChunks) {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        
        console.log(this.audio)
        this.audio.current.src = audioUrl;
        const playPromise = new Promise((resolve) => {
            this.audio.current.onended = resolve;
        });

        this.audio.current.onended = (event) => {
            console.log(
                "audio speak over",
            );
            this.audio.current.currentTime = 0
            this.isPlaying = false
            this.processBuffer()
        };

        this.audio.current.play().catch(error => {
            console.error('Audio playback failed:', error);
        });
        playPromise.then().catch();
    }

    async processBuffer() {
        // if (this.isPlaying || this.textBuffer.length === 0) {
        //     console.log("cancelled")
        //     return;
        // }
    
        // this.isPlaying = true;
    
        try {
            if (!this.isPlaying) { // dont pass data when stream already playing audio
                let text = ''
                while(this.textBuffer.length > 0 ) {
                    text += this.textBuffer.shift()
                }
                const textMessage = {
                    "text": text,
                    "try_trigger_generation": true,
                };
                console.log(textMessage)
                this.socket.send(JSON.stringify(textMessage));
            } else {
                console.log("Socket is not SET!!!!!!!!!!!!")
            }
            // await this.playAudioBuffer(audioData);
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    
        // if (this.textBuffer.length > 0) {
        //     this.processBuffer(); // Recursively process the buffer
        // }
    }

    async fetchAudioData(text) {
        console.log("playing audio text -> ", text)
        // const url = `https://api.elevenlabs.io/v1/text-to-speech/E01VVAfDdpbHObuCs5NH`;
        // const api_key = process.env.REACT_APP_ELAB_API_KEY
        // const headers = {
        //     Accept: 'audio/mpeg',
        //     'xi-api-key': api_key,
        //     'Content-Type': 'application/json',
        // };
        // const data = {
        //     text: text,
        //     model_id: 'eleven_turbo_v2',
        //     voice_settings: { stability: 0.6, similarity_boost: 0.85 },
        // };
    
        // const response = await fetch(url, {
        //     method: 'POST',
        //     headers: headers,
        //     body: JSON.stringify(data),
        //   });
    
        // return response.arrayBuffer();
    }

    async playStreamOld(textData) {
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${this.voiceId}/stream`;
        const api_key = process.env.REACT_APP_ELAB_API_KEY
        const headers = {
            Accept: 'audio/mpeg',
            'xi-api-key': api_key,
            'Content-Type': 'application/json',
        };
        const data = {
            text: textData,
            model_id: 'eleven_turbo_v2',
            voice_settings: { stability: 0.6, similarity_boost: 0.85 },
        };
        console.log("playing -> ", textData)
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            });
      
            if (response.ok) {
                this.isPlaying = true

                const reader = response.body.getReader();
                const audioChunks = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        console.log("don")
                        break;
                    };
                    audioChunks.push(value);
                    // console.log(value)
                }
                const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
                // const audioBlob = await response.blob();
                const audioUrl = URL.createObjectURL(audioBlob);
                
                console.log(this.audio)
                this.audio.current.src = audioUrl;
                const playPromise = new Promise((resolve) => {
                    this.audio.current.onended = resolve;
                });

                this.audio.current.onended = (event) => {
                console.log(
                    "audio speak over",
                );
                this.isPlaying = false
                };

                this.audio.current.play().catch(error => {
                    console.error('Audio playback failed:', error);
                });
                await playPromise;
            }
        } catch (error) {
            console.error('Error:', error);
            this.isPlaying = false
        }
    }
}



module.exports = AudioBuffer