import React from "react";
import { Button as MUIButton } from "@mui/material";

import theme from "../../../styles/theme";

export function PrimaryButton({ children, ...props }) {
  return (
    <MUIButton
      variant="contained"
      sx={{
        width: "100%",
        backgroundColor: theme.button.primary.bg, 
        "&:hover": {
          backgroundColor: theme.button.primary.hover, 
        },
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
}


export function PrimaryActionButton({ children, ...props }) {
  return (
    <MUIButton
      variant="contained"
      sx={{
        width: "100%",
        backgroundColor: props.condition === "true" ? theme.button.danger.bg: theme.button.primary.bg,
        color:  props.condition === "true" ? theme.button.danger.text: theme.button.primary.text,
        "&:hover": {
          backgroundColor: props.condition === "true" ? theme.button.danger.hover: theme.button.primary.hover, 
        },
        "&:disabled": {
          backgroundColor: theme.button.primary.bg,
        }
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
}


export function DialogButton({ children, ...props }) {
  return (
    <MUIButton
      variant="contained"
      sx={{
        backgroundColor: theme.button.primary.bg, 
        color: theme.button.primary.text,
        "&:hover": {
          backgroundColor: theme.button.primary.hover, 
        },
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
}

export function AlertActionButton({ children, ...props }) {
  return (
    <MUIButton
      variant="contained"
      
      {...props}
    >
      {children}
    </MUIButton>
  );
}
