import React from 'react';
import { TextField } from '../field';

const TextArea = (props) => {
  const {rows=3, ...rest} = props
  return (
    <TextField
      {...rest}
      sx=
      {{width: '100%'}}
      multiline
      rows={rows}
    />
  );
};

export default TextArea;
