import Card from "@mui/material/Card";

import { Box, Typography } from "@mui/material";

import * as theme from '../../../theme'
export default function PlanCard({ label, title, description, tokens, avatar, button }) {

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        overflow: "visible",
        border: '1px solid' + theme.TABLE_BG,
        borderRadius: 3,
        color: theme.TABLE_BG,
        minHeight: '50vh',
        px:{xs:2, md:5 },
        mx:{xs:2, md:0 },
        py:{xs:0, md:5 },
      }}
    >
      <Box mt={1} mx={0.5}>

        <Box sx={{backgroundColor: "white", color: "black", borderRadius:3, py:2}} mb={1}>
          <Typography variant="h5" textTransform="capitalize" >
            {title}
          </Typography>
        </Box>

        <Box m={3} display={'flex'} justifyContent={'center'} alignItems={'center'} lineHeight={0}>
            {avatar}
        </Box>
        
        <Typography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </Typography>

        <Box sx={{backgroundColor: "white", color: "black", borderRadius:3}} m={2}  >
          <Typography variant="h5" textTransform="capitalize" sx={{backgroundColor: theme.SUBSCRIBE_BUTTON, borderRadius: 3, px:3, display: 'inline-flex',}} >
            {tokens} Tokens
          </Typography>
        </Box>

        <Box m={3} lineHeight={0} minHeight={"50px"}>
          <Typography variant="button" fontWeight="light" color="text">
            {description}
          </Typography>
        </Box>
        
        <Box mb={3} lineHeight={0}>
          {button}
        </Box>
      </Box>
    </Card>
  );
}


