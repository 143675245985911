import React, { useState } from 'react';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import * as theme from '../../../theme'


const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            color: theme.BODY_FONT,
          },
          clearIndicator: {
            color: theme.BODY_FONT,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': theme.TEXT_FIELD_BORDER,
            '--TextField-brandBorderHoverColor': theme.TEXT_FIELD_HOVER,
            '--TextField-brandBorderFocusedColor': theme.TEXT_FIELD_FOCUS,
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
           
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)', 
            },
            
          },
        },
      },
      
     
    },
  });

  const SelectBox = (props) => {
    const { name, options, tooltipTitle = '', ...rest } = props;
    const outerTheme = useTheme();
  
    const [selectedValue, setSelectedValue] = useState(props.value || null);

    const selectedValues = React.useMemo(
      () => options.filter((v) => v.selected),
      [options],
    );
  
    return (
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Tooltip title={tooltipTitle} style={{ fontSize: '20px' }}>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label} // Provide a function to extract the label
            // value={options.find((option) => option.value === selectedValue) || []}
            value={selectedValues}
            getOptionSelected={(option, value) => option.value === value.value} // Customize equality check
            multiple={true}
            onChange={(event, newValue) => {
              if (props.onChange){

                setSelectedValue(newValue ? newValue.value : null);
                const event_target = {
                  target: {
                    name: name,
                    value: newValue ? newValue.value : '',
                  },
                };
                props.onChange(event_target);
              }
              return
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                value={selectedValue}
                onChange={(e) => {
                  if (props.onChange){
                    setSelectedValue(e.target.value);
                    props.onChange(e);

                  }
                  return
                }}
                {...rest}
                InputLabelProps={{
                  sx: { color: theme.TEXT_FIELD_LABEL },
                }}
              />
            )}
          />
        </Tooltip>
      </ThemeProvider>
    );
  };
  
  export default SelectBox;
  