export const TABLE_BG = "#ffffff"
export const TABLE_FONT = "#000000"

export const HEADER_BG = "#ffffff"
export const HEADER_FONT = "#000000"
export const FOOTER_BG = "#ffffff"
export const FOOTER_FONT = "#000000"

export const PRIMARY_BUTTON_LOADER = "#002A3A"

export const BODY_BG = "#002A3A"
export const BODY_FONT = "#ffffff"

export const CHATBOX_BG = "#ffffff"
export const CHATBOX_FONT = "#000000"

export const ASSISTANT_MESSAGE_BOX_BG = "#A7D2E8"
export const ASSISTANT_MESSAGE_BOX_FONT = "#000000"

export const USER_MESSAGE_BOX_BG = "#E0E0E0"
export const USER_MESSAGE_BOX_FONT = "#000000"

export const TEXT_FIELD_BORDER = "#ffffff"
export const TEXT_FIELD_HOVER = "#ffffff"
export const TEXT_FIELD_FOCUS = "#A7D2E8"
export const TEXT_FIELD_LABEL = "#A7D2E8"

export const PRIMARY_BUTTON = "#ffffff"
export const PRIMARY_BUTTON_FONT = "#000000"

export const SUBSCRIBED_BUTTON = "#2AB06F"
export const SUBSCRIBE_BUTTON = PRIMARY_BUTTON
export const SUBSCRIBE_BUTTON_FONT = PRIMARY_BUTTON_FONT
export const SUBSCRIBED_BUTTON_FONT = "#ffffff"

export const SECONDRY_BUTTON = "#ffffff"
export const SECONDRY_BUTTON_FONT = "#000000"

export const MAIN_BUTTON = "#ffffff"
export const MAIN_BUTTON_FONT = "#000000"

export const TITLE_BG = "#ffffff"
export const TITLE_FONT = "#000000"

export const WARNING_BG = "#191207"
export const WARNING_FONT = "#D9C09A"

export const SUCCESS_BG = "#198754"
export const SUCCESS_FONT = "#ffffff"