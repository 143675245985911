import React from 'react';
import { Grid, Box, Container, Typography } from '@mui/material'


import * as theme from '../../../theme'
import { IntegerTextField, TextField } from '../../custom/field';
import TextArea from '../../custom/textarea/TextArea';
import { SelectBox } from '../../custom/select';



export default function ViewAssistant({assistantData, businessOptions}) {

  const formData = assistantData

  function extractFileName(url) {
    const segments = url.split('/');
    const fileName = segments[segments.length - 1];
    return fileName;
  }

  return (
      
        <Container >

          <Grid container display={'flex'} justifyContent={'center'} sx={{px: 10}}>
            {/* Desktop version */}
            <Box sx={{ display: { xs: 'none', md: 'flex',  } }}>
              <Grid item xs={4} sx={{py: 5}}>
                <Box >
                  <img width={'100%'} alt='assistant' src={formData.image} />
                </Box>
              </Grid>
              <Grid item xs={8} sx={{ px:5, py:5 }} >
                <Box component="form"  >
                  <Grid container spacing={2}>
                  <Grid item xs={12}  >
                    <SelectBox label="Business" name="business" value={formData.business}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions}  required />
                  </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="name" label="Name"
                          value={formData.name}   required  />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField sx={{width: '100%'}} name="title" label="Title"
                          value={formData.title}   required  />
                    </Grid>
                  <Grid item xs={6}  >
                    <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
                        value={formData.order_number}  />
                  </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Description" name="description" value={formData.description}   sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Instructions" name="instructions" value={formData.instructions}   sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
                          value={formData.voice_id}  required    />
                    </Grid>
                    {/* <Grid item xs={6} >
                      <SelectBox label="Model" name="model"  value={formData.model}   sx={{backgroundColor: theme.BODY_BG}}  required />
                    </Grid> */}
                    {
                        formData.files?.length !== 0 &&
                        <>
                        <Grid item xs={12}>
                          <Typography variant='h6' textAlign={'left'}>
                          Files: 
                          </Typography>
                          <br/>
                          {
                            formData.files?.map((file, index) => (
                                <Box display={'flex'}>
                                  {index + 1}. <a href={file} target='_blank' rel="noreferrer" style={{marginRight: 10, marginLeft: 10, textDecoration: 'none', color: 'wheat', textWrap: 'nowrap',}} key={index}>{extractFileName(file)}</a>
                                </Box>
                              )
                            )
                          }
                        </Grid>
                        </>
                      } 
                    
                    
                  </Grid>
                </Box>
              </Grid>
              
            </Box>

            {/* Mobile Version */}
            <Box sx={{ display: { xs: 'block', md: 'none',  } }}>
              {/* Input  */}
              <Container component="main"  sx={{
                      display: "flex",
                      minHeight: '60vh',
                      padding:2
                    }}  id='signup' maxWidth="md" >
                <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '40vh'}}> 
                  <Box component="form" sx={{ color:theme.BODY_FONT }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} sx={{py: 2}}>
                        <img width={'50%'} alt='assistant' src={formData.image} />
                    </Grid>

                    <Grid item xs={12}  >
                    <SelectBox label="Business" name="business" value={formData.business}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions}  required />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="name" label="Name"
                          value={formData.name}   required  />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField sx={{width: '100%'}} name="title" label="Title"
                          value={formData.title}   required  />
                    </Grid>

                  <Grid item xs={6}  >
                    <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
                        value={formData.order_number} />
                  </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Description" name="description" value={formData.description}   sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Instructions" name="instructions" value={formData.instructions}   sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
                          value={formData.voice_id}  required    />
                    </Grid>
                    {/* <Grid item xs={6} >
                      <SelectBox label="Model" name="model"  value={formData.model}   sx={{backgroundColor: theme.BODY_BG}}  required />
                    </Grid> */}
                    
                    {
                        formData.files?.length !== 0 &&
                        <>
                        <Grid item xs={12}>
                          <Typography variant='h6' textAlign={'left'}>
                          Files: 
                          </Typography>
                          <br/>
                          {
                            formData.files?.map((file, index) => (
                                <Box display={'flex'}>
                                  {index + 1}. <a href={file} target='_blank' rel="noreferrer" style={{marginRight: 10, marginLeft: 10, textDecoration: 'none', textWrap: 'nowrap', color: 'wheat'}} key={index}>{extractFileName(file)}</a>
                                </Box>
                              )
                            )
                          }
                        </Grid>
                        </>
                      } 
                    </Grid>                  
                  </Box>
                </Box>
              </Container>
          
              
            </Box>
          </Grid>
  
        </Container>
   
           
  );
}