import React from 'react'

import { Box, Typography, Grid } from '@mui/material'

import * as theme from '../../theme'


export default function Title({text, buttons=[], mobileButtons=[]}) {

  return (
    <Box sx={{bgcolor: theme.TITLE_BG, mx:3, my:3, px:3, py:3, borderRadius:3, color: theme.TITLE_FONT}}>

      {/* Desktop version */}
      <Box sx={{ display: { xs: 'none', md: 'block',  } }}>
        <Grid container justifyContent="space-between">
          <Grid item>
                <Typography variant='h4'>
                  {text}
                </Typography>
          </Grid>
          <Grid item>
            
            <Grid container justifyContent="end">
              {
                buttons.map((button,index) => 
                <Grid key={index} item>
                  {button}
                </Grid> 
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
      
      {/* Mobile version */}
      <Box sx={{ display: { xs: 'block', md: 'none',  } }}>
        <Grid container justifyContent="space-between">
          <Grid item>
                <Typography variant='h6'>
                  {text}
                </Typography>
          </Grid>
          <Grid item>
            
            <Grid container justifyContent="end">
                {
                  mobileButtons.map((button,index) => 
                  <Grid key={index} item>
                    {button}
                  </Grid> 
                  )
                }
             </Grid>
          </Grid>
        </Grid>
      </Box>
      
    </Box>
  )
}
