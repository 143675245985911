
const theme = {
  palette: {
    primary: {
      bg: '#A7D2E8',
      text: "#00000",
    },
    secondary: {
      bg: '#E0E0E0',
      text: "#000000",
    },
  },
  button: {
    primary: {
      bg: '#ffffff',
      text: "#000000",
      hover: "#ffffff"
    },
    danger: {
      bg: '#f44336',
      text: "#00000",
      hover: "#570007"
    }
  },
  header: {
    primary: {
      bg: '#6BA0B2',
      text: "#fff",
      textI: "#000",
    }
  },
  footer: {
    primary: {
      bg: '#6BA0B2',
      text: "#fff",
      textI: "#000",
    }
  }
}

export default theme;

