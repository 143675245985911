import { useState } from 'react';
import { jwtDecode } from "jwt-decode";

function useToken() {

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  function removeToken() {
    localStorage.removeItem("token");
    setToken(null);
  }

  function getRole(){
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken.role
    return userRole
  }

  return {
    setToken: saveToken,
    token,
    removeToken,
    getRole
  }

}

export default useToken;