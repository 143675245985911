import React from 'react'
import { CircularProgress, Grid, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import * as theme from '../../../theme'


const LoaderBox = styled(Box)(({ c_theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: theme.BODY_BG,
  fontFamily: 'monospace'
}))


export default function LoadingMain({headline, paragraph}) {
  return (
    <LoaderBox>
      <Grid justifyContent="center" alignItems="center">
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress sx={{color: theme.BODY_FONT}} size={60}/>
        </Grid>   
        <Grid container justifyContent="center" alignItems="center" sx={{px:2.5}}> 
          <Typography  align="center" variant="h4"  style={{ marginTop: '20px', color: theme.BODY_FONT }}>{headline}</Typography>
        </Grid> 
        <Grid container justifyContent="center" alignItems="center" sx={{px:2.5}}> 
          <Typography  align="center" variant="subtitle2"  style={{ marginTop: '0px', color: theme.BODY_FONT }}>{paragraph}</Typography>
        </Grid> 
      </Grid> 
    </LoaderBox>
  )
}
