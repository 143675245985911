import React, { useState } from 'react'

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox } from '@mui/material';

import * as theme from '../../../theme'

export default function InstructionAlert({alert, handleAlertClose, onContinueClick }) {
  const [dontShowAgain, setDontShowAgain] = useState(true);

  const handleCheckboxChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  return (
    <div>
      <Dialog open={alert.open} onClose={handleAlertClose}>
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color: 'black'}} dangerouslySetInnerHTML={{__html: alert.message}}>
          </DialogContentText>
          {/* <FormControlLabel control={<Checkbox sx={{ '&.Mui-checked': { color: theme.BODY_BG, }, }} id='dont-show-again'  checked={dontShowAgain} onChange={handleCheckboxChange}  />} label="Don't show this again" /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onContinueClick?() => onContinueClick(dontShowAgain):handleAlertClose} sx={{color: theme.PRIMARY_BUTTON_FONT}} >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
