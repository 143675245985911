import React from 'react'

import { Box } from '@mui/material'

import Header from './header/Header'
import Footer from './footer/Footer'

import * as theme from '../../theme'
import Assistants from './assistants/Assistants'

export default function Admin({user, token,  removeToken, setUser, removeUser,  setLoading}) {
  return (
    
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Box >
        <Header type='home' user={user} removeToken={removeToken} removeUser={removeUser} setLoading={setLoading}  />
      </Box>
      <Box sx={{backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, padding: 4, minHeight: '80vh' }}>
        <Assistants user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} />
      </Box>
      <Box>
        <Footer />
      </Box>
    </div>
  )
}
