
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogButton } from './Button';

export default function Alert({alert, handleAlertClose}){


  return (
    <div>
      <Dialog open={alert.open} onClose={handleAlertClose}>
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent>
          <p>{alert.message}</p>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleAlertClose} variant="contained" >
            Close
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
