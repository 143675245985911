import { Box, Button } from '@mui/material'
import React from 'react'

export default function Assistant({img, title}) {
  return (
    <Box sx={{margin: 2}}>
      <img alt={img} width={'166px'} height={'188px'} src={require(`../../../assets/img/assistants/${img}`)} />
      <hr/>
    <Button edge="start" color="inherit" style={{fontSize: 12}}>
      {title}
    </Button>
    </Box>
  )
}
