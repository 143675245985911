import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import * as theme from '../../../theme'

export default function QAlert({alert, handleClose, handleAlertAgree, handleAlertDisagree}) {

  const stheme = useTheme();
  const fullScreen = useMediaQuery(stheme.breakpoints.down('md'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={alert.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        {alert.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus  sx={{color: theme.PRIMARY_BUTTON}} onClick={handleAlertDisagree}>
            Disagree
          </Button>
          <Button onClick={handleAlertAgree}  sx={{color: theme.PRIMARY_BUTTON}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}