
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AlertActionButton } from './Button';
import theme from "../../../styles/theme";


export default function OptionAlert({alert, handleAlertClose, options = []}){


  return (
    <div>
      <Dialog open={alert.open} onClose={handleAlertClose}>
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent>
          <p>{alert.message}</p>
        </DialogContent>
        <DialogActions>
          {
            options.map((option, index) => {
              return (
                <AlertActionButton sx={ 
                  {
                    backgroundColor: option.bgcolor? option.bgcolor: theme.button.primary.bg, 
                    color: option.color? option.color: theme.button.primary.text,
                    "&:hover": {
                      backgroundColor: option.bgcolorHover? option.bgcolorHover: theme.button.primary.hover, 
                    },
                 }} onClick={option.handler} variant="contained" >
                  {option.text}
                </AlertActionButton>
              )
            })
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};
