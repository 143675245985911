import React, { useEffect, useRef } from "react";
import { styled } from '@mui/system';

import * as theme from '../../theme'

import {
    Box,
    Container,
    FormControlLabel,
    Grid,
    List, 
    ListItem, 
    ListItemText, 
    Paper,
    Switch,
    Typography,
    useMediaQuery, 
} from "@mui/material";
import Avatar from "./Custom/Avatar";



const MessageBox = styled('div')`
    margin: 8px 0px;
    display: flex;
`;

const UserMessage = styled('div')(`
  background-color: ${theme.USER_MESSAGE_BOX_BG};
  color: ${theme.USER_MESSAGE_BOX_FONT};  
  padding: 8px 16px;
  padding-right: 16px;
  border-radius: 1rem;
  border-top-left-radius: 0;
  max-width: 80%;
  word-wrap: break-word;
`);

const AssistantMessage = styled('div')(`
  position: relative;
  background-color: ${theme.ASSISTANT_MESSAGE_BOX_BG};
  color: ${theme.ASSISTANT_MESSAGE_BOX_FONT};
  padding: 8px 16px;
  border-radius: 1rem;
  border-top-left-radius: 0;
  align-self: flex-end;
  max-width: 80%;
  word-wrap: break-word; 
`)

const MessageWrapper = styled('div')`
  display: flex;
  margin-bottom: 8px;
`;


const ChatBox = styled('div')({
    width: '100%',  
    height: '70vh',
    overflow: 'auto', 
    border: "1px solid lightgrey", 
    backgroundColor: theme.CHATBOX_BG,
    borderRadius: "20px",
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '5px'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#fff',
    },
    
    '@media screen and (max-device-width: 2560px) and (orientation: landscape)': {
        height: '70vh',
    },
});

export default function ConversationBox ({conversation=[], handleIsTextOnly, isTextOnly}) {
    const bottomRef = useRef(null);


    useEffect(() => {
        scrollToBottom();
      }, [conversation]);

    const scrollToBottom = () => {
        if (bottomRef.current) {
          if (typeof bottomRef.current.scrollIntoViewIfNeeded === 'function') {
            bottomRef.current.scrollIntoViewIfNeeded({ behavior: 'smooth' });
          } else {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
      };

    
    
return(
    <Grid xs={12}>
        <ChatBox>
            <Paper elevation={0} sx={{ padding: 2 }}>
                {
                    conversation.length > 0?
                    <List>
                        {conversation.map((message, index) => (
                        <ListItem key={index} sx={{ padding: 0 }}>
                            <ListItemText
                            sx={{ margin: 0 }}
                            primary={
                                <MessageWrapper align={message.role}>
                                {message.role === 'user' ? (
                                    <MessageBox>
                                        <Avatar role={message.role} />
                                        <UserMessage >
                                            {message.response}
                                        </UserMessage>
                                    </MessageBox>
                                ) : (
                                    <MessageBox>
                                        <Avatar role={message.role} />
                                        <AssistantMessage>
                                            {message.response}
                                        </AssistantMessage>
                                    </MessageBox>
                                )}
                                </MessageWrapper>
                            }
                            />
                        </ListItem>
                        ))}
                        <div ref={bottomRef} />
                    </List>:

                    <Container
                    sx={{
                        height: '60vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        
                        <Typography variant="p" textAlign={"center"} fontSize={20}>
                            No conversation Yet...
                        </Typography>
                    </Container>
                }
            </Paper>
        </ChatBox>
       
     <Box>
         <FormControlLabel control={
         <Switch 
         
         checked={isTextOnly}
         onChange={handleIsTextOnly}
         style={{
             color:'#ffffff',
             '&.Mui-checked': {
             color: '#ffffff',
             },
         }}/>
         } label="Only text output" />
     </Box>
    </Grid>
 
)

  }
