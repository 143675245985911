import React from 'react'

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import * as theme from '../../../theme'

export default function Alert({alert, handleAlertClose, onOKClick }) {
  return (
    <div>
      <Dialog open={alert.open} onClose={handleAlertClose}>
        <DialogTitle>{alert.title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color: 'black'}}>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOKClick?onOKClick:handleAlertClose} sx={{color: theme.PRIMARY_BUTTON_FONT}} >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
